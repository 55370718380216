<template>
  <div
    id="sidebar"
    class="
      h-screen
      hidden
      lg:block
      flex-shrink-0
      absolute
      lg:static
      left-0
      top-0
      z-1
      select-none
      animation-duration-300 animation-ease-in-out
    "
    style="width: 320px; backdrop-filter: blur(10px)"
  >
    <div class="flex flex-column h-full">
      <div class="flex align-items-center px-5">
        <div class="w-full text-center">
          <img src="@/assets/logo.png" style="width: 150px" class="mr-2" />
        </div>
      </div>
      <div class="overflow-y-auto">
        <ul class="list-none p-3 m-0 mt-5" v-if="currentUser.role == 'admin'">
          <li>
            <ul class="nav list-none p-0 m-0 overflow-hidden">
              <li>
                <router-link to="/clienti">
                  <i class="pi pi-file mr-2"></i>
                  <span class="font-medium">Documenti</span>
                  <span class="p-ink"></span>
                </router-link>
              </li>
              <li>
                <router-link to="/documenti-scaduti">
                  <i class="pi pi-exclamation-circle mr-2"></i>
                  <span class="font-medium">Scadenze</span>
                  <span class="p-ink"></span>
                </router-link>
              </li>
              <li>
                <router-link to="/certificazioni">
                  <i class="pi pi-folder mr-2"></i>
                  <span class="font-medium">Certificazioni</span>
                  <span class="p-ink"></span>
                </router-link>
              </li>
            </ul>
          </li>
        </ul>
        <ul class="list-none p-3 m-0 mt-5" v-else>
          <li>
            <ul class="nav list-none p-0 m-0 overflow-hidden">
              <li>
                <router-link :to="`/clienti/${currentUser.id}/documenti`">
                  <i class="pi pi-exclamation-circle mr-2"></i>
                  <span class="font-medium">Documenti</span>
                  <span class="p-ink"></span>
                </router-link>
              </li>
              <li>
                <router-link to="/certificazioni">
                  <i class="pi pi-folder mr-2"></i>
                  <span class="font-medium">Certificazioni</span>
                  <span class="p-ink"></span>
                </router-link>
              </li>
            </ul>
          </li>
        </ul>
      </div>

      <div class="flex align-items-center px-4">
        <div class="w-full">
          <h5 class="mb-3">Contatti</h5>
          <ul class="list-none p-0 m-0 overflow-hidden nav">
            <li class="flex align-content-center">
              <i class="pi pi-phone mr-2" style="line-height: 30px"></i>
              <a href="tel:0039017442042">+39 0174 42042</a>
            </li>
            <li class="flex align-content-center">
              <i class="pi pi-envelope mr-2" style="line-height: 30px"></i>
              <a href="mailto:segreteria@barberabilance.it"
                >segreteria@barberabilance.it</a
              >
            </li>
            <li class="flex align-content-center">
              <i class="pi pi-whatsapp mr-2" style="line-height: 30px"></i>
              <a href="whatsapp:00393914351318">+39 391 4351318</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    currentUser() {
      return this.$store.getters["auth/currentUser"];
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/theme/_variables.scss";

#sidebar {
  background-color: #fff;
  height: 100vh;
  padding: 40px 0;

  .nav {
    list-style: none;

    li {
      padding: 5px;
      text-align: left;

      a {
        color: $textColor;
        font-family: $fontFamily;
        line-height: 30px;
        text-decoration: none;
        vertical-align: middle;

        &:hover {
          color: $primaryColor;
        }

        &.router-link-active {
          color: $primaryColor;
        }
      }
    }
  }

  /// LOGO
  #logo {
    background-color: #fff;
    height: 150px;
    margin: auto;
    width: 150px;
  }
}
</style>