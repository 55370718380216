<template>
  <div
    id="main-content"
    class="flex flex-column min-h-screen relative flex-auto"
  >
    <!-- Top -->
    <div
      class="
        flex
        justify-content-between
        align-items-center
        px-5
        border-bottom-1
        relative
        lg:static
        bg-white
        border-white-alpha-40
      "
      style="height: 60px"
    >
      <div class="flex">
        <a
          class="
            cursor-pointer
            block
            lg:hidden
            text-gray-800
            mr-3
            mt-1
            p-ripple
          "
        >
          <i class="pi pi-bars text-4xl"></i>
          <span class="p-ink"></span>
        </a>
      </div>
      <a class="cursor-pointer block lg:hidden text-gray-800 p-ripple">
        <i class="pi pi-ellipsis-v text-2xl"></i>
        <span class="p-ink"></span>
      </a>
      <ul
        class="
          list-none
          p-0
          m-0
          hidden
          lg:flex lg:align-items-center
          select-none
          lg:flex-row
          right-0
          top-100
          z-1
          shadow-2
          lg:shadow-none
          absolute
          lg:static
          px-3
          py-3
          lg:py-0
          mr-3
          lg:mr-0
        "
        style="backdrop-filter: blur(10px)"
      >
        <li
          class="
            border-top-1
            lg:border-top-none lg:mt-0
            mt-2
            lg:pt-0
            pt-2
            border-white-alpha-40
          "
        >
          <a
            class="
              flex
              p-3
              lg:p-2
              align-items-center
              hover:bg-purple-50
              font-medium
              cursor-pointer
              transition-duration-150 transition-colors
              p-ripple
            "
            style="border-radius: 30px"
            @click="toggleMenu"
          >
            <div class="block">
              <span class="text-gray-700 font-medium text-sm"
                >Benvenuto {{ this.currentUser.name }}</span
              >
            </div>
            <span
              class="p-ink"
              style="height: 48px; width: 48px; top: -0.5px; left: -15px"
            ></span>
          </a>
          <Menu ref="menu" :model="menuItems" :popup="true" />
        </li>
      </ul>
    </div>

    <!-- <div class="flex flex-column flex-auto">
      <div class="bg-white-alpha-50 flex-auto"> -->
    <router-view />
    <!-- </div>
    </div> -->
  </div>
</template>

<script>
import { AUTH_LOGOUT } from "@/store/auth.module";
export default {
  data() {
    return {
      menuItems: [
        {
          label: "Esci",
          icon: "pi pi-eject",
          command: () => this.logout(),
        },
      ],
    };
  },

  computed: {
    currentUser() {
      return this.$store.getters["auth/currentUser"];
    },
  },

  methods: {
    toggleMenu() {
      this.$refs.menu.toggle(event);
    },

    logout() {
      this.$store.dispatch(AUTH_LOGOUT).finally(() => {
        /// --> Login
        this.$router.push({
          name: "login",
        });
      });
    },
  },
};
</script>

<style scoped>
#main-content {
  height: 100vh;
  max-height: 100vh;
  overflow-y: scroll;
}

#current-user-menu {
  background-color: #dfdfdf;
  height: 40px;
  margin: 10px 0;
  width: 40px;
}

#current-user-menu * {
  font-weight: bold;
  margin: auto;
}
</style>