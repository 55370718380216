import ApiService from "./api.service";

class CertificationsService {
  async allCertifications() {
    return await ApiService.get(`/certifications`);
  }

  async createCertification(params) {
    return await ApiService.postMultipart(`/certifications`, params);
  }

  async deleteCertification(id) {
    return await ApiService.delete(`/certifications/${id}`);
  }
  async sendNotifications() {
    return await ApiService.get(`/certifications/send_notifications`);
  }
}

export default new CertificationsService();
