<template>
  <div id="users-view">
    <div class="content col-12">
      <div class="container grid align-content-start">
        <div class="col-12">
          <Toolbar>
            <template #start></template>
            <template #end>
              <Button label="Aggiungi cliente" class="p-button-rounded" icon="pi pi-plus" @click="openForm" />
            </template>
          </Toolbar>
        </div>

        <Card class="col-12">
          <h5 class="col-12 text-center" v-if="users.length == 0">
            Nessun cliente trovato
          </h5>

          <template #content>
            <DataTable :value="users" filterDisplay="menu" :globalFilterFields="['name', 'vat']">
              <template #empty>
                <h5 class="col-12 text-center">Nessun cliente trovato</h5>
              </template>

              <template #header>
                <div class="flex justify-content-start">
                  <span class="p-input-icon-left">
                    <i class="pi pi-search" />
                    <InputText v-model="searchTxt" placeholder="Cerca per cliente o PIVA" style="width: 260px"
                      @keyup.enter="fetchAll()" />
                  </span>

                  <Button type="button" icon="pi pi-filter" label="Cerca"
                    class="p-button-outlined p-button-rounded ml-2" @click="fetchAll()" />
                </div>
              </template>
              <Column class="name">
                <template #body="row">
                  {{ row.data.name }}
                </template>

                <template #header> Cliente </template>

                <!-- <div style="display: block; width: 100%">
                    <InputText
                      type="text"
                      class="p-column-filter"
                      :placeholder="`Cerca cliente`"
                    />
                  </div>
                </template> -->
              </Column>
              <Column field="vat" header="P.IVA" class="vat"></Column>
              <Column class="has-expired-docs">
                <template #header> Documenti scaduti </template>
                <template #body="row">
                  <Chip :icon="`${
                      row.data.has_expired_docs
                        ? 'pi pi-exclamation-circle'
                        : 'pi pi-check'
                    }`" :class="`${
                      row.data.has_expired_docs ? 'chip-ko' : 'chip-ok'
                    }`" :label="`${
                      row.data.has_expired_docs ? 'Scaduti' : 'Nessuno'
                    }`" />
                </template>
              </Column>
              <Column class="actions">
                <!-- <template #body="row"> -->
                <template #body="row">
                  <div class="flex justify-content-end flex-wrap">
                    <Button class="delete-btn p-button p-button-danger p-button-text"
                      @click="confirmDelete($event, row.data.id)">Elimina</Button>

                    <Button class="p-button p-button-text" @click="openForm(row.data)">Modifica</Button>
                    <Button class="p-button p-button-text" @click="goToUserDetail(row.data)">Documenti</Button>
                    <!-- <Button
                      class="delete-btn p-button p-button-danger p-button-text"
                      @click="confirmDelete($event, row.data)"
                      >Elimina</Button
                    > -->
                  </div>
                </template>
              </Column>
            </DataTable>
          </template>
        </Card>
      </div>
    </div>
  </div>

  <ConfirmPopup>
    <template #message="slotProps">
      <div class="p-d-flex p-4">
        <i :class="slotProps.message.icon" style="font-size: 1.5rem"></i>
        <p class="p-pl-2">{{ slotProps.message.message }}</p>
      </div>
    </template>
  </ConfirmPopup>

  <Dialog id="user-form" v-model:visible="isFormVisible" :modal="true" :closable="true" :closeOnEscape="false"
    :showHeader="true">
    <template #header>
      <h3>{{ user.id != null ? "Modifica utente" : "Nuovo utente" }}</h3>
    </template>

    <div class="p-fluid">
      <form class="mt-4" @submit.prevent="submit">
        <FormInputText type="text" name="name" label="Nome *" :rules="form.name.rules" :value="form.name.value"
          :validationEnabled="validateForm" @update="updateFormInputText" />

        <FormInputText type="email" name="email" label="E-mail *" :rules="form.email.rules" :value="form.email.value"
          :validationEnabled="validateForm" :disabled="form.email.disabled" @update="updateFormInputText" />

        <FormInputText type="text" name="vat" label="PIVA *" :rules="form.vat.rules" :value="form.vat.value"
          :validationEnabled="validateForm" @update="updateFormInputText" />

        <FormInputText type="email" name="notification_emails" label="E-mail di notifica"
          tips="Nel caso di più indirizzi e-mail separarli da ," placeholder="Es: prima@gmail.com, seconda@gmail.com"
          :rules="form.notification_emails.rules" :value="form.notification_emails.value"
          :validationEnabled="validateForm" :disabled="form.notification_emails.disabled"
          @update="updateFormInputText" />
      </form>
    </div>

    <template #footer>
      <div class="mt-4 text-right">
        <Button type="submit" label="Salva" @click="submit" />
      </div>
    </template>
  </Dialog>
</template>

<script>
import { mapActions } from "vuex";
import { SHOW_LOADER, HIDE_LOADER } from "@/store/html_class.module";
import {
  USERS_ALL,
  USERS_CREATE,
  USERS_UPDATE,
  USERS_DELETE,
} from "@/store/users.module";

export default {
  data() {
    return {
      isFormVisible: false,
      user: null,

      searchTxt: "",

      /// Form
      validateForm: false,
      form: {
        name: {
          error: "",
          rules: { required: true },
          value: "",
        },

        vat: {
          error: "",
          rules: { required: true },
          value: "",
        },

        email: {
          disabled: false,
          error: "",
          rules: { required: true },
          value: "",
        },

        notification_emails: {
          disabled: false,
          error: "",
          rules: {},
          value: "",
        },
      },
    };
  },

  components: {
    // UserCard,
  },

  computed: {
    users() {
      return this.$store.state.users.all;
    },

    isFormValid() {
      return (
        this.form.name.error === "" &&
        this.form.vat.error === "" &&
        this.form.email.error === ""
      );
    },
  },

  methods: {
    ...mapActions([SHOW_LOADER, HIDE_LOADER]),

    filterNameCallback() {},

    cleanUpForm() {
      this.form.name.value = "";
      this.form.name.errors = "";

      this.form.vat.value = "";
      this.form.vat.errors = "";

      this.form.email.value = "";
      this.form.email.errors = "";

      this.form.notification_emails.value = "";
      this.form.notification_emails.errors = "";
    },

    confirmDelete(event, userId) {
      this.$confirm.require({
        target: event.currentTarget,
        message: "Sicuro di voler eliminare il cliente?",
        icon: "pi pi-exclamation-triangle",
        accept: () => {
          this.deleteUser(userId);
        },
        reject: () => {
          //callback to execute when user rejects the action
        },
      });
    },

    deleteUser(userId) {
      this.$store
        .dispatch(USERS_DELETE, userId)
        .then(() => {
          this.successDeleteCallback();
        })
        .catch((err) => {
          this.$store.dispatch(HIDE_LOADER);
          this.errorCallback(err);
        });
    },

    goToUserDetail(selectedUser) {
      this.$router.push({
        name: "documents",
        params: { id: selectedUser.id },
      });
    },

    openForm(selectedUser) {
      this.cleanUpForm();

      this.user = selectedUser;
      this.form.name.value = selectedUser.name;
      this.form.vat.value = selectedUser.vat;
      this.form.notification_emails.value = selectedUser.notification_emails;
      this.form.email.value = selectedUser.email;
      this.form.email.disabled = false; //selectedUser.id != null;

      this.isFormVisible = true;
    },

    updateFormInputText({ error, name, value }) {
      this.form[`${name}`].error = error;
      this.form[`${name}`].value = value;
    },

    submit() {
      this.validateForm = true;

      if (!this.isFormValid) {
        return;
      }

      this.$store.dispatch(SHOW_LOADER);
      this.isFormVisible = false;

      if (this.user.id != null) {
        /// Update
        const params = {
          user: {
            email: this.form.email.value,
            name: this.form.name.value,
            notification_emails: this.form.notification_emails.value,
            vat: this.form.vat.value,
          },
        };

        this.$store
          .dispatch(USERS_UPDATE, { params: params, id: this.user.id })
          .then(() => {
            this.successCallback();
          })
          .catch((err) => {
            this.$store.dispatch(HIDE_LOADER);
            this.isFormVisible = true;
            this.errorCallback(err);
          });
      } else {
        const params = {
          user: {
            role: "customer",
            name: this.form.name.value,
            notification_emails: this.form.notification_emails.value,
            vat: this.form.vat.value,
            email: this.form.email.value,
          },
        };

        /// Create
        this.$store
          .dispatch(USERS_CREATE, params)
          .then(() => {
            this.successCallback();
          })
          .catch((err) => {
            this.$store.dispatch(HIDE_LOADER);
            this.isFormVisible = true;
            this.errorCallback(err);
          });
      }
    },

    fetchAll() {
      this.$store.dispatch(SHOW_LOADER);

      this.$store.dispatch(USERS_ALL, this.searchTxt).finally(() => {
        this.$store.dispatch(HIDE_LOADER);
      });
    },

    successDeleteCallback() {
      this.isFormVisible = false;
      this.$store.dispatch(HIDE_LOADER);
      this.fetchAll();

      this.$toast.add({
        detail: "Cliente eliminato correttamente",
        life: 3000,
        severity: "success",
        summary: "Clienti",
      });
    },

    successCallback() {
      this.isFormVisible = false;
      this.$store.dispatch(HIDE_LOADER);
      this.fetchAll();

      this.$toast.add({
        detail: "Cliente salvato correttamente",
        life: 3000,
        severity: "success",
        summary: "Clienti",
      });
    },

    errorCallback(err) {
      this.$toast.add({
        detail: err[0],
        life: 3000,
        severity: "error",
        summary: "Errore",
      });
    },
  },

  mounted() {
    this.fetchAll();
  },

  name: "UsersView",
};
</script>

<style lang="scss">
@import "@/assets/theme/_variables.scss";

.p-datatable-table .has-expired-docs {
  width: 20%;

  .chip-ok {
    background-color: $primaryLighterColor;
    color: #fff;
  }

  .chip-ko {
    background-color: $dangerButtonBg;
    color: #fff;
  }
}

#users-view .p-datatable-table .actions {
  width: 30% !important;
}
</style>