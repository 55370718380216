import UsersService from '../services/users.service';

// action types
export const USERS_ALL = "users/fetchAll";
export const USERS_UPDATE = "users/updateUser";
export const USER_DETAIL = "users/userDetail";
export const USERS_CREATE = "users/createUser";
export const USERS_DELETE = "users/deleteUser";
export const USERS_SEND_CREDENTIALS = "users/sendCredentials";


export const users = {
  namespaced: true,

  state: {
    all: [],
  },

  actions: {
    fetchAll(ctx, search) {
      UsersService.allUsers(search).then(
        response => {
          ctx.commit('setUsers', response.data.users);
        },
        error => {
          ctx.commit('setUsers', []);
          return Promise.reject(error);
        });
    },

    createUser(ctx, params) {
      return UsersService.createUser(params).then(
        response => {
          return Promise.resolve(response.data);
        },
        error => {
          return Promise.reject(error);
        }
      );
    },

    userDetail(ctx, id) {
      return UsersService.userDetail(id).then(
        response => {
          return Promise.resolve(response.data);
        },
        error => {
          return Promise.reject(error);
        }
      );
    },

    updateUser(ctx, { params, id }) {
      return UsersService.updateUser(id, params).then(
        response => {
          return Promise.resolve(response.data);
        },
        error => {
          return Promise.reject(error);
        }
      );
    },

    deleteUser(ctx, id) {
      return UsersService.deleteUser(id).then(
        response => {
          return Promise.resolve(response.data);
        },
        error => {
          return Promise.reject(error);
        }
      );
    },

    sendCredentials(ctx, id) {
      return UsersService.sendCredentials(id).then(
        response => {
          return Promise.resolve(response.data);
        },
        error => {
          return Promise.reject(error);
        }
      );

    }
  },

  getters: {
  },

  mutations: {
    setUsers(state, users) {
      state.all = users;
    }
    // loginSuccess(state, user) {
    //   state.loggedIn = true;
    //   state.user = user;
    // },

    // loginFailure(state) {
    //   state.loggedIn = false;
    //   state.user = null;
    // },

    // logout(state) {
    //   state.loggedIn = false;
    //   state.user = null;
    // },
  }
};