<template>
  <div id="users-view">
    <div class="content col-12">
      <div class="container grid align-content-start">
        <div class="col-12">
          <Toolbar>
            <template #start>
              <div class="mb-5 mt-0" v-if="this.user != null">
                <h5 class="m-0 mb-1">
                  {{ this.user.name }}
                </h5>
                <label>
                  {{ `P.IVA: ${this.user.vat} - ${this.user.email}` }}
                </label>
              </div>
            </template>
            <template #end>
              <div
                class="mb-5 mt-0 flex-column"
                v-if="currentUser.role == 'admin'"
              >
                <div class="m-0 mb-1 flex justify-content-end">
                  <Button
                    class="p-button p-button-outlined p-button-rounded"
                    icon="pi pi-envelope"
                    label="Invia credenziali"
                    @click="sendCredentials()"
                    v-if="currentUser.role == 'admin'"
                  />
                </div>

                <div v-if="this.user != null && currentUser.role == 'admin'">
                  <label
                    class="mt-2 mr-1"
                    v-if="this.user.credentials_sent_at != null"
                  >
                    {{
                      `Ultimo invio: ${this.$moment(
                        this.user.credentials_sent_at
                      ).format("DD/MM/YYYY - HH:mm")}`
                    }}
                  </label>
                  <label class="mt-2 mr-1" v-else>
                    Credenziali non inviate
                  </label>
                </div>
              </div>
            </template>
          </Toolbar>
        </div>

        <TabView
          class="col-12"
          lazy
          id="task-tab"
          :activeIndex="tabActiveIndex"
          @tab-click="tabClick($event)"
          @tab-change="tabChange($event)"
        >
          <TabPanel header="Certificati e scadenze">
            <div class="col-12" v-if="currentUser.role == 'admin'">
              <Toolbar>
                <template #start>
                  <Button
                    icon="pi pi-arrow-left"
                    class="p-button-text"
                    label="Indietro"
                    @click="$router.go(-1)"
                    v-if="this.user != null && this.user.role == 'admin'"
                  /><br />
                </template>
                <template #end>
                  <Button
                    label="Aggiungi certificato/scadenza"
                    class="p-button-rounded"
                    icon="pi pi-plus"
                    @click="openForm"
                  />
                </template>
              </Toolbar>
            </div>

            <Panel header="Filtri" :toggleable="true" class="mb-3">
              <!-- Filters -->
              <form class="col-12 flex" @submit.prevent="fetchDocuments()">
                <div class="col-2">
                  <label class="block"> Tipo verifica</label>
                  <Dropdown
                    v-model="filterForm.certificationType"
                    :options="certificationType"
                    optionLabel="label"
                  />
                </div>

                <div class="col-2 mr-4">
                  <label class="block">Matricola strumento</label>
                  <InputText type="text" v-model="filterForm.code.value" />
                </div>
                <!-- <div class="col-2 mr-4">
                  <div class="p-field">
                    <label class="block"> Data inizio validità</label>

                    <Calendar
                      v-model="filterForm.validFrom"
                      :inline="false"
                      dateFormat="dd/mm/yy"
                    />
                  </div>
                </div> -->
                <div class="col-2 mr-4">
                  <div class="p-field">
                    <label class="block">Validità fino al</label>

                    <Calendar
                      v-model="filterForm.validTo"
                      :inline="false"
                      dateFormat="dd/mm/yy"
                    />
                  </div>
                </div>
                <div class="col-3 mt-3">
                  <Button
                    type="submit"
                    label="Filtra"
                    @click="submit"
                    class="mr-2"
                  />
                  <Button
                    type="button"
                    label="Annulla"
                    @click="clearFilterForm()"
                    class="p-button-secondary"
                  />
                </div>
              </form>
              <!-- End Filters -->
            </Panel>

            <Card class="col-12">
              <template #content>
                <h5
                  class="col-12 text-center"
                  v-if="certifications.length == 0"
                >
                  Nessun certificato caricato
                </h5>
                <template v-else>
                  <Accordion :multiple="true" :activeIndex="activeIndexes">
                    <AccordionTab
                      v-for="(value, key) in certifications_group"
                      :key="key"
                      :header="'ANNO ' + key" 
                    >
                    <DataTable :value="value">
                    <Column
                      field="category"
                      header="Categoria strumento"
                      class="category"
                    ></Column>

                    <Column field="code" header="Matricola" class="code">
                    </Column>

                    <Column field="name" header="Nome" class="name"> </Column>

                    <Column
                      field="certification_type"
                      header="Tipo verifica"
                      class="certification_type"
                    >
                    </Column>

                    <Column header="Validità" class="validity">
                      <template #body="row">
                        <span>{{
                          this.$moment(row.data.valid_from).format("DD/MM/YYYY")
                        }}</span>
                        <span v-if="row.data.valid_to != null"
                          ><br />{{
                            this.$moment(row.data.valid_to).format("DD/MM/YYYY")
                          }}</span
                        >
                      </template>
                    </Column>

                    <Column class="expired-docs">
                      <template #body="row">
                        <div
                          :class="`${
                            row.data.is_expired ? 'chip-ko' : 'chip-ok'
                          }`"
                        ></div>
                      </template>
                    </Column>

                    <Column class="actions">
                      <template #body="row">
                        <div class="flex justify-content-end flex-wrap">
                          <Button
                            class="p-button p-button-text"
                            @click="openDocument(row.data.pdf_url)"
                            >Scarica</Button
                          >
                          <Button
                            class="delete-btn p-button p-button-danger p-button-text"
                            @click="confirmDelete($event, row.data.id)"
                            v-if="currentUser.role == 'admin'"
                            >Elimina</Button
                          >
                        </div>
                      </template>
                    </Column>
                  </DataTable>
                    </AccordionTab>
                  </Accordion>
                </template>
              </template>
            </Card>
          </TabPanel>

          <TabPanel header="Altri documenti">
            <div class="col-12">
              <Toolbar v-if="currentUser.role == 'admin'">
                <template #start>
                  <Button
                    icon="pi pi-arrow-left"
                    class="p-button-text"
                    label="Indietro"
                    @click="$router.go(-1)"
                    v-if="this.user != null && this.user.role == 'admin'"
                  /><br />
                </template>
                <template #end>
                  <Button
                    label="Aggiungi documento"
                    class="p-button-rounded"
                    icon="pi pi-plus"
                    @click="openForm"
                  />
                </template>
              </Toolbar>
            </div>

            <Card class="col-12">
              <template #content>
                <h5 class="col-12 text-center" v-if="documents.length == 0">
                  Nessun documento caricato
                </h5>
                <template v-else>
                  <DataTable :value="documents">
                    <Column field="name" header="Nome" class="name"> </Column>
                    <Column
                      field="description"
                      header="Note/Descrizione"
                      class="description"
                    >
                    </Column>
                    <Column class="actions">
                      <template #body="row">
                        <div
                          class="flex justify-content-end flex-wrap"
                          v-if="currentUser.role == 'admin'"
                        >
                          <Button
                            class="p-button p-button-text"
                            @click="openDocument(row.data.pdf_url)"
                            >Scarica</Button
                          >
                          <Button
                            class="delete-btn p-button p-button-danger p-button-text"
                            @click="confirmDelete($event, row.data.id)"
                            >Elimina</Button
                          >
                        </div>
                      </template>
                    </Column>
                  </DataTable>
                </template>
              </template>
            </Card>
          </TabPanel>
        </TabView>
      </div>
    </div>
  </div>

  <ConfirmPopup>
    <template #message="slotProps">
      <div class="p-d-flex p-4">
        <i :class="slotProps.message.icon" style="font-size: 1.5rem"></i>
        <p class="p-pl-2">{{ slotProps.message.message }}</p>
      </div>
    </template>
  </ConfirmPopup>

  <Dialog
    id="user-form"
    v-model:visible="isFormVisible"
    :modal="true"
    :closable="true"
    :closeOnEscape="false"
    :showHeader="true"
    v-if="tabActiveIndex == 0"
  >
    <template #header>
      <h3>Nuovo Certificato/Scadenza</h3>
    </template>

    <div class="p-fluid">
      <form class="mt-4" @submit.prevent="submit">
        <div class="mt-1 mb-4">
          <label> Categoria strumento *</label>
          <AutoComplete
            v-model="form.category"
            :suggestions="categoryOpts"
            :dropdown="true"
            :forceSelection="true"
            @complete="loadFilterCategory($event)"
            field="label"
            class="w-100 mt-1 mr-4"
          />
        </div>

        <FormInputText
          type="text"
          name="code"
          label="Matricola strumento *"
          :rules="form.code.rules"
          :value="form.code.value"
          :validationEnabled="validateForm"
          @update="updateFormInputText"
        />

        <div class="p-field mt-2 mb-4">
          <label class="mb-4"> Tipo verifica * </label>
          <div class="p-field-radiobutton mb-2 mt-2">
            <RadioButton
              name="certificationType"
              value="iso"
              v-model="form.certificationType"
            />
            <label class="ml-2">ISO</label>
          </div>
          <div class="p-field-radiobutton mb-2" v-if="tabActiveIndex == 0">
            <RadioButton
              name="certificationType"
              value="metrica"
              v-model="form.certificationType"
            />
            <label class="ml-2">Metrica</label>
          </div>
        </div>

        <div class="p-field mt-4">
          <label class="mb-2"> Data inizio validità *</label>

          <Calendar
            v-model="form.validFrom"
            :inline="false"
            dateFormat="dd/mm/yy"
          />

          <small class="mt-1 mb-5 p-error`"></small>
        </div>

        <div class="p-field mt-4">
          <label class="mb-2"> Data fine validità *</label>

          <Calendar
            v-model="form.validTo"
            :inline="false"
            dateFormat="dd/mm/yy"
          />

          <small class="mt-1 mb-5 p-error`"></small>
        </div>

        <div class="mt-4 mb-5">
          <label> Allegato * </label>

          <FileUpload
            class="mt-2"
            mode="basic"
            name="attachment[]"
            v-model="form.attachment"
            :customUpload="true"
            @select="selectAttachment"
          />
        </div>
      </form>
    </div>

    <template #footer>
      <div class="mt-4 text-right">
        <Button type="submit" label="Salva" @click="submit" />
      </div>
    </template>
  </Dialog>

  <Dialog
    id="document-form"
    v-model:visible="isFormVisible"
    :modal="true"
    :closable="true"
    :closeOnEscape="false"
    :showHeader="true"
    v-if="tabActiveIndex == 1"
  >
    <template #header>
      <h3>Nuovo documento</h3>
    </template>

    <div class="p-fluid">
      <form class="mt-4" @submit.prevent="submit">
        <FormInputText
          type="text"
          name="description"
          label="Descrizione *"
          :rules="form.description.rules"
          :value="form.description.value"
          :validationEnabled="validateForm"
          @update="updateFormInputText"
        />

        <div class="mt-4 mb-5">
          <label> Allegato * </label>

          <FileUpload
            class="mt-2"
            mode="basic"
            name="attachment[]"
            v-model="form.attachment"
            :customUpload="true"
            @select="selectAttachment"
          />
        </div>
      </form>
    </div>

    <template #footer>
      <div class="mt-4 text-right">
        <Button type="submit" label="Salva" @click="submit" />
      </div>
    </template>
  </Dialog>
</template>

<script>
import { mapActions } from "vuex";
import { SHOW_LOADER, HIDE_LOADER } from "@/store/html_class.module";
import { USER_DETAIL, USERS_SEND_CREDENTIALS } from "@/store/users.module";

import {
  DOCUMENTS_ALL,
  DOCUMENTS_CREATE,
  DOCUMENTS_DELETE,
} from "@/store/documents.module";

export default {
  data() {
    const route = this.$router.currentRoute.value;

    return {
      tabActiveIndex: 0,

      isFormVisible: false,
      user: null,
      certifications_group: [],
      userId: route.params.id,

      categoryOpts: [],
      certificationType: [
        { label: "ISO", value: "ISO" },
        { label: "Metrica", value: "Metrica" },
      ],

      /// Form
      validateForm: false,
      form: {
        // Categoria strumento
        category: {
          label: "",
          value: null,
        },

        // Matricola strumento
        code: {
          error: "",
          rules: { required: true },
          value: "",
        },

        // Tipo di verifica
        certificationType: {
          error: "",
          rules: { required: true },
          value: { label: "", value: null },
        },

        description: {
          error: "",
          rules: { required: true },
          value: "",
        },

        validFrom: null,
        validTo: null,
        attachment: null,
      },
      filterForm: {
        // Matricola strumento
        code: {
          value: "",
        },

        // Tipo di verifica
        certificationType: {
          value: { label: "", value: null },
        },
        validFrom: null,
        // validTo: null,
      },
      activeIndexes:[]
    };
  },

  components: {},

  computed: {
    currentUser() {
      return this.$store.getters["auth/currentUser"];
    },

    certifications() {
      const certif_length = this.$store.state.documents.certifications.length;
      if(certif_length > 0){
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.certifications_group = this.groupByYear(this.$store.state.documents.certifications, 'valid_from');
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.activeIndexes = Array.from(Array(certif_length).keys())
      } 
      return this.$store.state.documents.certifications;
    },

    documents() {
      return this.$store.state.documents.all;
    },

    isFormValid() {
      if (this.tabActiveIndex == 0) {
        // Certificati e scadenze
        return (
          this.form.category.value !== null &&
          this.form.certificationType !== null &&
          this.form.code.error === "" &&
          this.form.validFrom !== null &&
          this.form.validTo !== null &&
          this.form.attachment !== null
        );
      } else {
        // Altri documenti
        return (
          this.form.description.error === "" && this.form.attachment !== null
        );
      }
    },
  },

  methods: {
    ...mapActions([SHOW_LOADER, HIDE_LOADER]),

    openForm() {
      this.cleanUpForm();
      this.isFormVisible = true;
    },

    tabChange(e) {
      this.tabActiveIndex = e.index;
    },

    tabClick(e) {
      this.tabActiveIndex = e.index;
    },

    buildFormData(formData, data, parentKey) {
      if (
        data &&
        typeof data === "object" &&
        !(data instanceof Date) &&
        !(data instanceof File)
      ) {
        Object.keys(data).forEach((key) => {
          this.buildFormData(
            formData,
            data[key],
            parentKey ? `${parentKey}[${key}]` : key
          );
        });
      } else {
        const value = data == null ? "" : data;

        formData.append(parentKey, value);
      }
    },

    loadFilterCategory() {
      setTimeout(() => {
        this.categoryOpts = [
          ...[
            {
              label: "BILANCIA NON AUTOMATICA",
              value: "BILANCIA NON AUTOMATICA",
            },
            { label: "BILANCIA AUTOMATICA", value: "BILANCIA AUTOMATICA" },
            { label: "DINAMOMETRO", value: "DINAMOMETRO" },
            { label: "MANOMETRO", value: "MANOMETRO" },
            { label: "MASSA CAMPIONE", value: "MASSA CAMPIONE" },
            { label: "METALDETECTOR", value: "METALDETECTOR" },
            { label: "PESA AEREA", value: "PESA AEREA" },
            { label: "PESA ASSI", value: "PESA ASSI" },
            { label: "PESA BESTIAME", value: "PESA BESTIAME" },
            { label: "PESA FERROVIARIA", value: "PESA FERROVIARIA" },
            { label: "PESA PONTE", value: "PESA PONTE" },
            { label: "PESA RUOTE", value: "PESA RUOTE" },
            {
              label: "REGISTRATORE TELEMATICO",
              value: "REGISTRATORE TELEMATICO",
            },
            { label: "TERMOIGROMETRO", value: "TERMOIGROMETRO" },
            { label: "TERMOBILANCIA", value: "TERMOBILANCIA" },
            { label: "TERMOMETRO", value: "TERMOMETRO" },
          ],
        ];
      }, 250);
    },

    jsonToFormData(data) {
      const formData = new FormData();
      this.buildFormData(formData, data);
      return formData;
    },

    cleanUpForm() {
      this.form.category = { label: "", value: null };

      this.form.code.value = "";
      this.form.code.error = "";

      this.form.certificationType = null;

      this.form.description.value = "";
      this.form.description.error = "";

      this.form.validFrom = new Date();
      this.form.validTo = this.$moment(new Date()).add(1, "years").toDate();
      this.form.attachment = null;
    },

    clearFilterForm() {
      this.filterForm.category = { label: "", value: null };
      this.filterForm.code.value = "";
      this.filterForm.code.error = "";
      this.filterForm.certificationType = null;
      // this.filterForm.validFrom = null;
      this.filterForm.validTo = null;
      this.fetchDocuments();
    },

    sendCredentials() {
      this.$store.dispatch(SHOW_LOADER);

      this.$store
        .dispatch(USERS_SEND_CREDENTIALS, this.user.id)
        .then(() => {
          this.fetchUser();

          this.$toast.add({
            detail: "Credenziali inviate correttamente",
            life: 3000,
            severity: "success",
            summary: "Documenti",
          });
        })
        .catch((err) => {
          this.errorCallback(err);
        })
        .finally(() => {
          this.$store.dispatch(HIDE_LOADER);
        });
    },

    confirmDelete(event, documentId) {
      this.$confirm.require({
        target: event.currentTarget,
        message: "Sicuro di voler eliminare il documento?",
        icon: "pi pi-exclamation-triangle",
        accept: () => {
          this.deleteDocument(documentId);
        },
        reject: () => {
          //callback to execute when user rejects the action
        },
      });
    },

    deleteDocument(documentId) {
      this.$store.dispatch(SHOW_LOADER);

      this.$store
        .dispatch(DOCUMENTS_DELETE, documentId)
        .then(() => {
          this.successDeleteCallback();
        })
        .catch((err) => {
          this.$store.dispatch(HIDE_LOADER);
          this.errorCallback(err);
        });
    },

    updateFormInputText({ error, name, value }) {
      this.form[`${name}`].error = error;
      this.form[`${name}`].value = value;
    },

    submit() {
      this.validateForm = true;

      if (!this.isFormValid) {
        return;
      }

      this.$store.dispatch(SHOW_LOADER);
      this.isFormVisible = false;

      const params = {
        document: {
          document_type: this.tabActiveIndex == 0 ? "certification" : "other",
          certification_type: this.form.certificationType,
          code: this.form.code.value,
          category: this.form.category.value,
          description: this.form.description.value,
          user_id: this.userId,
          valid_from: this.form.validFrom,
          valid_to: this.form.validTo,
        },
      };

      let formData = this.jsonToFormData(params);
      formData.append("[document][pdf]", this.form.attachment);

      this.$store.dispatch(SHOW_LOADER);

      /// Create
      this.$store
        .dispatch(DOCUMENTS_CREATE, formData)
        .then(() => {
          this.successCallback();
        })
        .catch((err) => {
          this.$store.dispatch(HIDE_LOADER);
          this.isFormVisible = true;
          this.errorCallback(err);
        });
    },

    fetchUser() {
      this.$store.dispatch(SHOW_LOADER);

      this.$store
        .dispatch(USER_DETAIL, this.userId)
        .then((user) => {
          this.user = user.user;

          this.fetchDocuments();
        })
        .catch((err) => {
          this.$store.dispatch(HIDE_LOADER);
          this.isFormVisible = false;
          this.errorCallback(err);
        });
    },

    fetchDocuments() {
      this.$store.dispatch(SHOW_LOADER);

      const params = {
        userId: this.userId,
        certification_type:
          this.filterForm.certificationType &&
          typeof this.filterForm.certificationType.value === "string"
            ? this.filterForm.certificationType.value.toLowerCase()
            : null,
        code:
          this.filterForm.code.value != "" ? this.filterForm.code.value : null,
        // valid_from: this.filterForm.validFrom ? this.$moment(this.filterForm.validFrom).format("YYYY/MM/DD") : null,
        valid_to: this.filterForm.validTo
          ? this.$moment(this.filterForm.validTo).format("YYYY/MM/DD")
          : null,
      };

      this.$store
        .dispatch(DOCUMENTS_ALL, params)
        .then((docs) => {
          console.log(docs);
        })
        .catch((err) => {
          this.isFormVisible = false;
          this.errorCallback(err);
        })
        .finally(() => {
          this.$store.dispatch(HIDE_LOADER);
        });
    },

    openDocument(url) {
      window.open(url, "_blank");
    },

    successDeleteCallback() {
      this.isFormVisible = false;
      this.$store.dispatch(HIDE_LOADER);
      this.fetchDocuments();

      this.$toast.add({
        detail: "Documento eliminato correttamente",
        life: 3000,
        severity: "success",
        summary: "Documenti",
      });
    },

    successCallback() {
      this.isFormVisible = false;
      this.$store.dispatch(HIDE_LOADER);
      this.fetchDocuments();

      this.$toast.add({
        detail: "Documento salvato correttamente",
        life: 3000,
        severity: "success",
        summary: "Documenti",
      });
    },

    errorCallback(err) {
      this.$toast.add({
        detail: err[0],
        life: 3000,
        severity: "error",
        summary: "Errore",
      });
    },

    selectAttachment(event) {
      this.form.attachment = event.files[0];
    },
    groupByYear(xs, key) {
      return xs.reduce(function (rv, x) {
        (rv[x[key].split("-")[0]] = rv[x[key].split("-")[0]] || []).push(x);
        return rv;
      }, {});
    }
  },

  mounted() {
    if (this.user == null) {
      this.fetchUser();
    } else {
      this.fetchDocuments();
    }
  },

  name: "Documents",
};
</script>

<style lang="scss">
@import "@/assets/theme/_variables.scss";

.p-datatable-table .name .p-datatable-table .category {
  width: 20%;
}
.p-datatable-table .description {
  width: 30%;
}
.p-datatable-table .validity {
  width: 15%;

  .expired {
    color: $errorColor;
  }
}
.p-datatable-table .actions {
  text-align: right;
  width: 20%;
}
.p-datatable-table .expired-docs {
  width: 15%;
}
.p-accordion .p-accordion-header .p-accordion-header-link{
  background-color: rgb(0 0 0 / 5%) !important;
  color: $shade700 !important;
  span.p-accordion-header-text{
    font-weight: 500 !important;
  }
}
.p-accordion .p-accordion-header:not(.p-disabled) .p-accordion-header-link:focus{
  box-shadow: none !important;
}

.p-tabview-nav {
  border-bottom: 1px solid $primaryColor !important;

  li {
    .p-tabview-nav-link {
      border-bottom: 0 !important;

      .p-tabview-title {
        color: $primaryColor;
        font-weight: normal;
      }
    }

    &.p-highlight .p-tabview-nav-link {
      border-bottom: 3px solid $primaryColor !important;
      * {
        font-weight: bold !important;
      }
    }
  }
}

.p-radiobutton .p-radiobutton-box.p-focus {
  border: 4px solid $primaryColor;
}
</style>
