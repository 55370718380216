<template>
  <div id="users-view">
    <div class="content col-12">
      <div class="container grid align-content-start">
        <div class="col-12">
          <Toolbar v-if="currentUser.role == 'admin'">
            <template #start>
                  <Button
                    class="p-button p-button-outlined p-button-rounded mr-3"
                    icon="pi pi-envelope"
                    label="Notifica aziende"
                    @click="openNotificationsConfirm()"
                  />
              <Button
                label="Aggiungi certificato"
                class="p-button-rounded"
                icon="pi pi-plus"
                @click="openForm"
              />
            </template>
          </Toolbar>
        </div>

        <Card class="col-12">
          <template #content>
            <h5 class="col-12 text-center" v-if="certifications.length == 0">
              Nessun certificato caricato
            </h5>
            <template v-else>
              <DataTable :value="certifications">
                <Column field="name" header="Certificato" class="name"> </Column>

                <Column class="actions">
                  <template #body="row">
                    <div class="flex justify-content-end flex-wrap">
                      <Button
                        class="p-button p-button-text"
                        @click="openDocument(row.data.pdf_url)"
                        >Scarica</Button
                      >
                      <Button
                        class="delete-btn p-button p-button-danger p-button-text"
                        @click="confirmDelete($event, row.data.id)"
                        v-if="currentUser.role == 'admin'"
                        >Elimina</Button
                      >
                    </div>
                  </template>
                </Column>
              </DataTable>
            </template>
          </template>
        </Card>
      </div>
    </div>
  </div>

  <ConfirmPopup>
    <template #message="slotProps">
      <div class="p-d-flex p-4 confirm-popup">
        <i :class="slotProps.message.icon" style="font-size: 1.5rem"></i>
        <p class="p-pl-2">{{ slotProps.message.message }}</p>
      </div>
    </template>
  </ConfirmPopup>

  <Dialog
    id="user-form"
    v-model:visible="isFormVisible"
    :modal="true"
    :closable="true"
    :closeOnEscape="false"
    :showHeader="true"
  >
    <template #header>
      <h3>Aggiungi Certificato</h3>
    </template>

    <div class="p-fluid">
      <form class="mt-4" @submit.prevent="submit">
        <FormInputText
          type="text"
          name="name"
          label="Nome file*"
          :rules="form.name.rules"
          :value="form.name.value"
          :validationEnabled="validateForm"
          @update="updateFormInputText"
        />

        <div class="mt-4 mb-5">
          <label> Allegato * </label>
          <FileUpload
            class="mt-2"
            mode="basic"
            name="attachment[]"
            v-model="form.attachment"
            :customUpload="true"
            @select="selectAttachment"
          />
          <span class="mt-1 mb-3 p-error" v-if="attachmentError">Campo obbligatorio</span>
        </div>
      </form>
    </div>

    <template #footer>
      <div class="mt-4 text-right">
        <Button type="submit" label="Salva" @click="submit" />
      </div>
    </template>
  </Dialog>
</template>

<script>
import { mapActions } from "vuex";
import { SHOW_LOADER, HIDE_LOADER } from "@/store/html_class.module";

import {
  CERTIFICATIONS_ALL,
  CERTIFICATIONS_CREATE,
  CERTIFICATIONS_DELETE,
  CERTIFICATIONS_NOTIFICATIONS
} from "@/store/certifications.module";

export default {
  data() {
    return {
      isFormVisible: false,

      /// Form
      validateForm: false,
      attachmentError:false,
      form: {
        name: {
          error: "",
          rules: { required: true },
          value: "",
        },
        attachment: null,
      },
    };
  },

  components: {},

  computed: {
    currentUser() {
     return this.$store.getters["auth/currentUser"];
    },
    certifications() {
      return this.$store.state.certifications.all;
    },

    isFormValid() {
      return this.form.name.error === "" && this.form.attachment !== null;
    },
  },

  methods: {
    ...mapActions([SHOW_LOADER, HIDE_LOADER]),

    openForm() {
      this.cleanUpForm();
      this.isFormVisible = true;
    },

    buildFormData(formData, data, parentKey) {
      if (
        data &&
        typeof data === "object" &&
        !(data instanceof Date) &&
        !(data instanceof File)
      ) {
        Object.keys(data).forEach((key) => {
          this.buildFormData(
            formData,
            data[key],
            parentKey ? `${parentKey}[${key}]` : key
          );
        });
      } else {
        const value = data == null ? "" : data;

        formData.append(parentKey, value);
      }
    },

    jsonToFormData(data) {
      const formData = new FormData();
      this.buildFormData(formData, data);
      return formData;
    },

    cleanUpForm() {
      this.form.name.value = "";
      this.form.attachment = null;
      this.attachmentError = false;
      this.validateForm = false;
    },

    confirmDelete(event, documentId) {
      this.$confirm.require({
        target: event.currentTarget,
        message: "Sicuro di voler eliminare il documento?",
        icon: "pi pi-exclamation-triangle",
        accept: () => {
          this.deleteDocument(documentId);
        },
        reject: () => {
          //callback to execute when user rejects the action
        },
      });
    },

    deleteDocument(documentId) {
      this.$store.dispatch(SHOW_LOADER);

      this.$store
        .dispatch(CERTIFICATIONS_DELETE, documentId)
        .then(() => {
          this.successDeleteCallback();
        })
        .catch((err) => {
          this.$store.dispatch(HIDE_LOADER);
          this.errorCallback(err);
        });
    },

    updateFormInputText({ error, name, value }) {
      this.form[`${name}`].error = error;
      this.form[`${name}`].value = value;
    },

    submit() {
      this.validateForm = true;

      if(this.form.attachment == null){
          this.attachmentError = true;
      }else{
          this.attachmentError = false;
      }

      if (!this.isFormValid) {
        return;
      }

      this.$store.dispatch(SHOW_LOADER);
      this.isFormVisible = false;

      const params = {
        certification: {
          name: this.form.name.value,
        },
      };

      let formData = this.jsonToFormData(params);
      formData.append("[certification][pdf]", this.form.attachment);

      this.$store.dispatch(SHOW_LOADER);

      /// Create
      this.$store
        .dispatch(CERTIFICATIONS_CREATE, formData)
        .then(() => {
          this.successCallback();
        })
        .catch((err) => {
          this.$store.dispatch(HIDE_LOADER);
          this.isFormVisible = true;
          this.attachmentError = false;
          this.errorCallback(err);
        });
    },

    fetchCertifications() {
      this.$store.dispatch(SHOW_LOADER);

      this.$store
        .dispatch(CERTIFICATIONS_ALL)
        .then((docs) => {
          console.log(docs);
        })
        .catch((err) => {
          this.isFormVisible = false;
          this.errorCallback(err);
        })
        .finally(() => {
          this.$store.dispatch(HIDE_LOADER);
        });
    },

    openDocument(url) {
      window.open(url, "_blank");
    },

    successDeleteCallback() {
      this.isFormVisible = false;
      this.$store.dispatch(HIDE_LOADER);
      this.fetchCertifications();

      this.$toast.add({
        detail: "Documento eliminato correttamente",
        life: 3000,
        severity: "success",
        summary: "Documenti",
      });
    },

    successCallback() {
      this.isFormVisible = false;
      this.$store.dispatch(HIDE_LOADER);
      this.fetchCertifications();

      this.$toast.add({
        detail: "Documento salvato correttamente",
        life: 3000,
        severity: "success",
        summary: "Documenti",
      });
    },

    errorCallback(err) {
      this.$toast.add({
        detail: err[0],
        life: 3000,
        severity: "error",
        summary: "Errore",
      });
    },

    selectAttachment(event) {
      this.form.attachment = event.files[0];
      this.attachmentError = false;
    },
    sendNotifications() {
      this.$store.dispatch(SHOW_LOADER);

      this.$store
        .dispatch(CERTIFICATIONS_NOTIFICATIONS)
        .then(() => {

          this.$toast.add({
            detail: "Notifica inviata correttamente",
            life: 3000,
            severity: "success",
            summary: "Certificazioni",
          });
        })
        .catch((err) => {
          this.errorCallback(err);
        })
        .finally(() => {
          this.$store.dispatch(HIDE_LOADER);
        });
    },
    openNotificationsConfirm(){
      this.$confirm.require({
        target: event.currentTarget,
        message: "Stai per inviare una mail di notifica a tutte le aziende per avvisare che sono disponibili nuove certificazioni. Confermi l'invio?",
        icon: "pi pi-exclamation-triangle",
        class: 'notifications',
        accept: () => {
          this.sendNotifications();
        },
        reject: () => {
          //callback to execute when user rejects the action
        },
      });
    }
  },

  mounted() {
    this.fetchCertifications();
  },

  name: "Certifications",
};
</script>

<style lang="scss">
@import "@/assets/theme/_variables.scss";

.p-datatable-table .actions {
  text-align: right;
  width: 20%;
}
.p-dialog .p-dialog-header{
    padding-left: 40px !important;
    padding-right: 40px !important;
}

.p-error{
    font-size: 13px;
}

.p-toolbar{
    float:right;
}

.confirm-popup{
  max-width: 400px;
  p{
    line-height: 1.4rem;
  }
}
</style>
