import ApiService from './api.service';

class UsersService {
  async allUsers(search) {
    return await ApiService.get('/users', search ? `role=user&search=${search}` : 'role=user');
  }

  async userDetail(id) {
    return await ApiService.get(`/users/${id}`);
  }

  async sendCredentials(id) {
    return await ApiService.get(`/users/${id}/send_credentials`);
  }

  async createUser(params) {
    return await ApiService.post('/users', params);
  }

  async updateUser(id, params) {
    return await ApiService.put(`/users/${id}`, params);
  }

  async deleteUser(id) {
    return await ApiService.delete(`/users/${id}`);
  }
}


export default new UsersService();
